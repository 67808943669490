import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { SEO } from '@karakuri-faq/component'

import { SettingYaml } from '../graphql'
export interface Data {
  site: any
  settingYaml: SettingYaml
}
export interface SEOProps {
  title?: string
  description?: string
  banner?: string
  pathname?: string
}

export const SEOWidget: React.FC<SEOProps> = ({ title, description, banner, pathname }) => {
  const { site, settingYaml }: Data = useStaticQuery(query)

  const { buildTime } = site
  const {
    title: defaultTitle,
    description: defaultDescription,
    url: siteUrl,
    headerImageUrl: defaultBanner,
    language,
    twitter,
    facebook
  } = settingYaml
  const seoProps = {
    title,
    description,
    banner,
    pathname,
    buildTime,
    defaultTitle: defaultTitle || '',
    defaultDescription: defaultDescription || '',
    siteUrl: siteUrl || '',
    defaultBanner: defaultBanner || '',
    language: language || '',
    twitter: twitter || '',
    facebook: facebook || ''
  }
  return <SEO {...seoProps} />
}
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      # siteMetadata {
      #   # siteUrl
      #   # defaultTitle: title
      #   # defaultDescription: description
      #   # defaultBanner: banner
      #   # headline
      #   # siteLanguage
      #   # ogLanguage
      #   # author
      #   # twitter
      #   # facebook
      # }
    }
    settingYaml(ignore: { ne: true }) {
      title
    }
  }
`
